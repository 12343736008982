
const router_modular = [
    /*{
        path: '/',
        name: 'home',
        component: () => import('@/views/HomeView')
    },*/
    // ----------------------------核酸--------------------------
    {
        path: '/nucleicVisit',
        name: 'nucleicVisit',
        component: () => import('@/views/nucleic/nucleicVisit'),
        meta: {
            title:"核酸上门采样",
            keepAlive: true // 需要缓存页面
        }
    },
    {
        path: '/nucleicMessage',
        name: 'nucleicMessage',
        component: () => import('@/views/nucleic/nucleicMessage'),
        meta:{
            title:"康来生物-核酸个人信息登记",
        }
    },
    {
        path: '/userQRcode',
        name: 'userQRcode',
        component: () => import('@/views/nucleic/userQRcode'),
        meta:{
            title:"康来生物-核酸上门-个人信息二维码",
        }
    },
    {
        path: '/nucleicAgreement',
        name: 'nucleicAgreement',
        component: () => import('@/views/nucleic/nucleicAgreement'),
        meta:{
            title:"新型冠状病毒上门核酸检测知情同意书",
            keepAlive: true
        }
    },
    {
        path: '/applyRecordList',
        name: 'applyRecordList',
        component: () => import('@/views/nucleic/applyRecordList'),
        meta:{
            title:"康来生物-核酸上门采样服务-申请记录",
        }
    },
    {
        path: '/applyUserList',
        name: 'applyUserList',
        component: () => import('@/views/nucleic/applyUserList'),
        meta:{
            title:"康来生物-核酸上门采样服务-用户列表",
        }
    },
    {
        path: '/applyDetail',
        name: 'applyDetail',
        component: () => import('@/views/nucleic/applyDetail'),
        meta:{
            title:" 康来生物-核酸上门采样服务-申请详情",
        }
    },
    {
        path: '/nucleicPay',
        name: 'nucleicPay',
        component: () => import('@/views/nucleic/nucleicPay'),
        meta:{
            title:"康来生物-采样申请待支付列表",
        }
    },
    {
        path: '/nucleicLeaving',
        name: 'nucleicLeaving',
        component: () => import('@/views/nucleic/nucleicLeaving'),
        meta:{
            title:"康来生物-留言面板",
        }
    },
    {
        path: '/groupInvitation',
        name: 'groupInvitation',
        component: () => import('@/views/nucleic/groupInvitation'),
        meta:{
            title:"康来生物-核酸上门采样-一起来拼团",
        }
    },
    {
        path: '/groupJoin',
        name: 'groupJoin',
        component: () => import('@/views/nucleic/groupJoin'),
        meta:{
            title:"康来生物-核酸上门采样-一起来拼团",
        }
    },
    {
        path: '/groupLaunch',
        name: 'groupLaunch',
        component: () => import('@/views/nucleic/groupLaunch'),
        meta:{
            title:"康来生物-核酸上门采样-一起来拼团",
        }
    },
    {
        path: '/groupPayInfo',
        name: 'groupPayInfo',
        component: () => import('@/views/nucleic/groupPayInfo'),
        meta:{
            title:"康来生物-核酸上门采样-一起来拼团",
        }
    },
    {
        path: '/addNurse',
        name: 'addNurse',
        component: () => import('@/views/nucleic/addNurse'),
        meta:{
            title:"康来生物-护士注册",
        }
    },
    {
        path: '/sampleTask',
        name: 'sampleTask',
        component: () => import('@/views/nucleic/sampleTask'),
        meta:{
            title:"康来生物-采样任务",
        }
    },
    {
        path: '/sampleInfo',
        name: 'sampleInfo',
        component: () => import('@/views/nucleic/sampleInfo'),
        meta:{
            title:"康来生物-采样任务详情",
        }
    },
    // ----------------------------报告单--------------------------
    {
        path: '/addReport',
        name: 'addReport',
        component: () => import('@/views/report/addReport'),
        meta:{
            title:"康来生物-当日达-冷链服务",
        }
    },
    {
        path: '/searchReport',
        name: 'searchReport',
        component: () => import('@/views/report/searchReport'),
        meta:{
            keepAlive:true,
            title:"康来生物-核酸报告单查询",
        }
    },
    {
        path: '/reportList',
        name: 'reportList',
        component: () => import('@/views/report/reportList'),
        meta:{
            title:"康来生物-核酸报告单列表",
        }
    },
    // --------------------------2023年10月28日新增-----
    {
        path: '/searchInspectReport',
        name: 'searchInspectReport',
        component: () => import('@/views/report/searchInspectReport'),
        meta:{
            title:"康来生物-检验报告查询",
        }
    },
    {
        path: '/inspectReportList',
        name: 'inspectReportList',
        component: () => import('@/views/report/inspectReportList'),
        meta:{
            title:"康来生物-检验报告单列表",
        }
    },
    // --------------------------税务发票-----------------------------
    {
        path: '/checkInvoice',
        name: 'checkInvoice',
        component: () => import('@/views/invoice/checkInvoice'),
        meta:{
            title:"康来生物-税务发票",
        }
    },
    // --------------------------物流查询-----------------------------
    {
        path: '/logistics',
        name: 'logistics',
        component: () => import('@/views/logistics/logistics'),
        meta:{
            title:"康来生物-物流查询",
        }
    },
    // --------------------------kics智慧呼叫-----------------------------
    {
        path: '/kics/customerAgreement',
        name: 'customerAgreement',
        component: () => import('@/views/kics/customerAgreement'),
        meta:{
            title:"小康超级管家",
        }
    },
    {
        path: '/kics/cqsxNotice',
        name: 'cqsxHospitalNotice',
        component: () => import('@/views/kics/cqsxHospitalNotice'),
        meta:{
            title:"就医须知",
        }
    },
    {
        path: '/kics/cqsxQuestion',
        name: 'cqsxQuestion',
        component: () => import('@/views/kics/cqsxQuestion'),
        meta:{
            title:"问卷调查",
        }
    },
    {
        path: '/kics/businessAgreement',
        name: 'businessAgreement',
        component: () => import('@/views/kics/businessAgreement'),
        meta:{
            title:"小康云商家",
        }
    },
    {
        path:"/addVillage",
        name:'addVillage',
        component:()=>import("@/views/kics/addVillage"),
        meta:{
            title:"小区申请",
            keepAlive: true // 需要缓存页面
        }
    },
    // --------------------------新闻页-----------------------------
    {
        path: '/spnNew2',
        name: 'spnNew2',
        component: () => import('@/views/spnNews/spnNew2'),
        meta:{
            title:"新闻页",
        }
    },
    {
        path: '/spnNew3',
        name: 'spnNew3',
        component: () => import('@/views/spnNews/spnNew3'),
        meta:{
            title:"新闻页",
        }
    },
    {
        path: '/spnNew4',
        name: 'spnNew4',
        component: () => import('@/views/spnNews/spnNew4'),
        meta:{
            title:"新闻页",
        }
    },
    {
        path: '/spnNew5',
        name: 'spnNew5',
        component: () => import('@/views/spnNews/spnNew5'),
        meta:{
            title:"新闻页",
        }
    },
    // -------------------------------会员专享-------------------
    {
        path: '/employResult',
        name: 'employResult',
        component: () => import('@/views/employ/employResult'),
        meta:{
            title:"会员专享结果",
        }
    },
    {
        path: '/employSearch',
        name: 'employSearch',
        component: () => import('@/views/employ/employSearch'),
        meta:{
            title:"会员专享查询",
        }
    },
    // -------------------------------微信h5页面-------------------
    {
        path: '/kics/wxH5',
        name: 'wxH5',
        component: () => import('@/views/kics/wxH5'),
        meta:{
            title:"微信小程序",
        }
    },

    // -------------------------------app直连网页-------------------

    {
        path: '/app/userAgreement',
        name: 'userAgreement',
        component: () => import('@/views/app/userAgreement'),
        meta:{
            title:"用户协议",
        }
    },
    {
        path: '/app/privacyPolicy',
        name: 'privacyPolicy',
        component: () => import('@/views/app/privacyPolicy'),
        meta:{
            title:"隐私政策",
        }
    },
    
     // -------------------------------文档直链-------------------
     {
        path: '/document/oneEscortContract',
        name: 'oneEscortContract',
        component: () => import('@/views/document/oneEscortContract'),
        meta:{
            title:"一对一陪护合同",
        }
    },
    {
        path: '/document/moreEscortContract',
        name: 'moreEscortContract',
        component: () => import('@/views/document/moreEscortContract'),
        meta:{
            title:"一对多陪护合同",
        }
    },
    {
        path: '/document/elderlyRiskNotice',
        name: 'elderlyRiskNotice',
        component: () => import('@/views/document/elderlyRiskNotice'),
        meta:{
            title:"住院老人潜在意外风险告知书",
        }
    },

     // -------------------------------空产品-------------------

     {
        path: '/app/goodsList',
        name: 'userAgreement',
        component: () => import('@/views/app/goodsList'),
        meta:{
            title:"产品列表",
        }
    },

    // -------------------------------2024.5.11新增医生查询报告单和登录绑定-------------------
    {
        path: '/doctorReport/getReportList',
        name: 'getReportList',
        component: () => import('@/views/doctorReport/getReportList'),
        meta:{
            title:"查询报告单列表",
        }
    },
     {
        path: '/doctorReport/login',
        name: 'doctorLogin',
        component: () => import('@/views/doctorReport/login'),
        meta:{
            title:"登录/绑定",
        }
    },

]
export default router_modular
